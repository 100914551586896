<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 34.4 34.4"
  >
    <g fill="currentColor">
      <path
        d="M0 17.2A17.2 17.2 0 1 0 17.2 0 17.2 17.2 0 0 0 0 17.2Zm32.772 0A15.572 15.572 0 1 1 17.2 1.629 15.593 15.593 0 0 1 32.771 17.2Z"
      />
      <path
        d="M16.386 9.024v14.383l-5.157-5.155a.841.841 0 0 0-1.153 0 .841.841 0 0 0 0 1.155l6.548 6.579a.739.739 0 0 0 .271.17.94.94 0 0 0 .611 0 .739.739 0 0 0 .271-.17l6.547-6.579a.819.819 0 0 0 0-1.153.841.841 0 0 0-1.153 0l-5.157 5.153V8.99a.815.815 0 1 0-1.628.034Z"
      />
    </g>
  </svg>
</template>
